import React, { useCallback, useMemo, useState } from 'react';
import { Table, Button, Form, Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react';
import { IMemberStore } from '../../models/MemberStore';
import { colors } from '../../theme/colors';
import * as _ from "lodash";
import { useHistory } from 'react-router-dom';
import { MemberLocation } from '../../Locations';
import { useStores } from '../../hooks/UseStores';
import blankProfileImage from '../../img/blank-profile.png'
interface EventGuestListTableProps {
  memberStore: IMemberStore;
  onLoadMore: () => void;
  onSort: (sorting: string) => void;
  currentSorting: string;
}

interface ColumnDefinition {
  key: string;
  header: string;
  accessor: (member: any) => React.ReactNode;
  sortable?: boolean;
  sortKey?: string;
  onClick?: (member: any) => void;
}

const useColumnDefinitions = (): ColumnDefinition[] => {
  const { t } = useTranslation();
  const history = useHistory();
  const { userSessionStore } = useStores()
   
  return useMemo(() => [
    {
      key: 'name',
      header: 'eventScreen.name',
      accessor: (member) => (
        <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
          <img
            src={member.profileImages?.small || blankProfileImage}
            alt={member.name}
            style={{
              width: 35,
              height: 35,
              borderRadius: '50%',
              marginRight: 10
            }}
          />
          <span>{member.name}</span>
        </div>
      ),
      sortable: true,
      sortKey: 'name',
      onClick: (member) => {
          history.push(MemberLocation.toUrl({
            ...userSessionStore!.selectedBranch!.urlFields(),
            memberId: member!.id!
          }))
      },
    },
    {
      key: 'activated',
      header: t('eventScreen.activated'),
      accessor: (member) => member.eventsActivated ? t('yes') : t('no'),
      sortable: true,
      sortKey: 'eventsActivated',
    },
    {
      key: 'tickets',
      header: 'eventScreen.tickets',
      accessor: (member) => member.eventsTotalTickets || '-',
      sortable: true,
      sortKey: 'eventsTotalTickets',
    },
    {
      key: 'cloakrooms',
      header: 'eventScreen.cloakrooms',
      accessor: (member) => member.eventsTotalCloakrooms || '-',
      sortable: true,
      sortKey: 'eventsTotalCloakrooms',
    },
    {
      key: 'totalSpent',
      header: 'eventScreen.totalSpent',
      accessor: (member) => member.eventsTotalSpent ? `${(member.eventsTotalSpent / 100).toFixed(2)} €` : '-',
      sortable: true,
      sortKey: 'eventsTotalSpent',
    },
  ], [t]);
};

const EventGuestListTable = observer(({ memberStore, onLoadMore, onSort, currentSorting }: EventGuestListTableProps) => {
  const { t } = useTranslation();
  const [accessFilter, setAccessFilter] = useState('all');
  const [cardFilter, setCardFilter] = useState('all');
   const [searchTerm, setSearchTerm] = useState(memberStore.filter.searchByName || '');
  const columnDefinitions = useColumnDefinitions();

  const debouncedSearch = useCallback(
    _.debounce((value: string) => {
      memberStore.fetchMembers({ name: value });
    }, 500),
    []
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };


  const handleClearSearch = () => {
    setSearchTerm('')
    memberStore.fetchMembers({ name: '' });
  };

  const handleAccessFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setAccessFilter(e.target.value);
    // Implement access filter logic
  };

  const handleCardFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCardFilter(e.target.value);
    // Implement card filter logic
  };

  const handleSort = (column: ColumnDefinition) => {
    if (column.sortable && column.sortKey) {
      const newSorting = currentSorting === column.sortKey ? `-${column.sortKey}` : column.sortKey;
      onSort(newSorting);
    }
  };

  const getSortIcon = (column: ColumnDefinition) => {
    if (!column.sortable || !column.sortKey) return null;

    if (currentSorting === column.sortKey) {
      return <FontAwesomeIcon icon={['fas', 'caret-up']} className="ml-2" />;
    } else if (currentSorting === `-${column.sortKey}`) {
      return <FontAwesomeIcon icon={['fas', 'caret-down']} className="ml-2" />;
    } else {
      return null
      
    }
  };

  return (
    <>
        <Card className="mb-3">
        <Card.Body>
            <Row>
              <Col md={4}>
                <Form.Group className="d-flex m-0">
                  <Form.Control 
                    type="text" 
                    placeholder={t('eventScreen.searchWithName')} 
                    value={searchTerm} 
                    onChange={handleSearch}
                  />
                  <Button variant="outline-secondary" disabled={!Boolean(searchTerm)} onClick={handleClearSearch} className="ml-2">
                    {t('eventScreen.clear')}
                  </Button>
                </Form.Group>
              </Col>
              {/* <Col md={4}>
                <Form.Control as="select" value={accessFilter} onChange={handleAccessFilterChange}>
                  <option value="all">{t('eventScreen.waitingForAccess')}</option>
                  <option value="approved">{t('eventScreen.approved')}</option>
                  <option value="waiting">{t('eventScreen.waiting')}</option>
                </Form.Control>
              </Col>
              <Col md={4}>
                <Form.Control as="select" value={cardFilter} onChange={handleCardFilterChange}>
                  <option value="all">{t('eventScreen.selectCards')}</option>
                  <option value="hasCard">{t('eventScreen.hasCard')}</option>
                  <option value="noCard">{t('eventScreen.noCard')}</option>
                </Form.Control>
              </Col> */}
            </Row>
      </Card.Body>
      </Card>
      <div className="text-muted mb-3">
        {t('eventScreen.showingMembers', { shown: memberStore.filteredMembers.length, total: memberStore.filter.totalMembers })}
      </div>
      <Table responsive hover>
        <thead>
          <tr>
            {columnDefinitions.map((column) => (
              <th 
                key={column.key} 
                onClick={() => handleSort(column)} 
                style={{ cursor: column.sortable ? 'pointer' : 'default', borderTop: `1px solid ${colors.stroke}`, borderBottom: `1px solid ${colors.stroke}` }}
              >
                {t(column.header)}
                {getSortIcon(column)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {memberStore.filteredMembers.map((member: any) => (
            <tr key={member.id}>
              {columnDefinitions.map((column) => (
                <td onClick={() => column.onClick && column.onClick(member)} key={`${member.id}-${column.key}`}
                 className={`${column.key === 'name' ? 'text-left' : 'text-center'} ${column.onClick ? 'hover-underline' : ''}`}>
                  {column.accessor(member)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      {memberStore.shouldShowMoreMembersButton && (
        <div className="text-center mt-3">
          <Button variant="secondary" onClick={onLoadMore}>
            <FontAwesomeIcon icon={['fal', 'chevrons-down']} className="mr-2" />
            {t('eventScreen.loadMore')}
          </Button>
        </div>
      )}
    </>
  );
});

export default EventGuestListTable;