import Location from "react-app-location"
import * as Yup from "yup"
import slugify from "slugify"

const OrganizationParameter = {
    organization: Yup.string(),
    venue: Yup.string(),
    group: Yup.string(),
    idType: Yup.string().required(),
    id: Yup.string().required(),
}

interface OrganizationParameterProps {
    organization?: string;
    venue?: string;
    group?: string;
    idType: string; 
    id: string;
}

const SignupParameter = {
    invitationKey: Yup.string().required(),
}

const ResetPasswordParameter = {
    resetToken: Yup.string(),
}

const FilterQueryStrings = {
    startDate: Yup.string(),
    endDate: Yup.string(),
}

export function getUrlSlug(text: string) {
    return slugify(text, {
        replacement: "",    // replace spaces with replacement
        remove: /[ *+~.()'"!:@]/g,        // regex to remove characters
        lower: false,         // result in lower case
    })
}

export const RootLocation = new Location("/")

export const LoginLocation = new Location("/login")

export const ResetPasswordLocation = new Location("/resetPassword/:resetToken?", ResetPasswordParameter)

export const SignupLocation = new Location("/signup/:invitationKey", SignupParameter)

export const SuperAdminLocation = new Location("/superAdmin")

export const LogoutLocation = new Location("/logout")

export const BenefitsLocation = new Location("/manage/:organization?/:venue?/:group?/benefits/:idType/:id", OrganizationParameter, FilterQueryStrings)
export const BenefitsModifyLocation = new Location("/manage/:organization?/:venue?/:group?/benefits/modify/:idType/:id", OrganizationParameter)

export const MembersLocation = new Location("/manage/:organization?/:venue?/:group?/members/:idType/:id", OrganizationParameter, FilterQueryStrings)

export const ManageVenuesLocation = new Location("/manage/:organization?/venues", OrganizationParameter, FilterQueryStrings)
export const ModifyVenueLocation = new Location("/manage/:organization?/venue/:venueId/modify", {
    ...OrganizationParameter,
    venueId: Yup.string()
})

export const CreateVenueLocation = new Location("/manage/:organization?/venue/new", {
    ...OrganizationParameter,
})

export const EmailOctopusApiKeyLocation = new Location("/manage/:organization?/emailOctopus", {
    ...OrganizationParameter,
})

export const ManageEmailOctopusApiKeyLocation = new Location("/manage/:organization?/emailOctopus/manage", {
    ...OrganizationParameter,
})

export const MemberLocation = new Location("/manage/:organization?/:venue?/:group?/members/profile/:memberId?/:idType/:id/", {
    ...OrganizationParameter,
    memberId: Yup.string()
})

export const MembersStatusesModifyLocation = new Location("/manage/:organization?/:venue?/:group?/members/statuses/modify/:memberId?/:idType/:id/:statusType?", {
    ...OrganizationParameter,
    memberId: Yup.string(),
    statusType: Yup.string(),
})

export const MembersStatusSubscriptionsModifyLocation = new Location("/manage/:organization?/:venue?/:group?/members/statusSubscriptions/modify/:memberId?/:idType/:id/:statusType?", {
    ...OrganizationParameter,
    memberId: Yup.string(),
    statusType: Yup.string(),
})

export const MemberStatusSubscriptionModifyLocation = new Location("/manage/:organization?/:idType/:id/:venueId?/statusSubscriptionType/:statusSubscriptionType/modify/:statusSubscriptionId", {
    ...OrganizationParameter,
    venueId: Yup.string(),
    statusSubscriptionType: Yup.string().required(),
    statusSubscriptionId: Yup.string().required()
})

export const EventsLocation = new Location("/manage/:organization?/:venue?/:group?/events/:idType/:id", OrganizationParameter, FilterQueryStrings)
export const EventLocation = new Location("/manage/:organization?/:venue?/:group?/events/:idType/:id/:eventId", {
    ...OrganizationParameter,
    eventId: Yup.string().required()
  })
  
export const EventsModifyLocation = new Location("/manage/:organization?/:venue?/:group?/events/modify/:idType/:id/:eventId?/:createBasedOn?", {
    ...OrganizationParameter,
    eventId: Yup.string(),
    createBasedOn: Yup.string()
})
export const ProductsLocation = new Location("/manage/:organization?/:venue?/:group?/products/:idType/:id", OrganizationParameter, FilterQueryStrings)
export const ProductModifyLocation = new Location("/manage/:organization?/:idType/:id/:venueId?/product/modify/:productId", {
    ...OrganizationParameter,
    venueId: Yup.string(),
    productId: Yup.string().required()
})
export const StampCardModifyLocation = new Location("/manage/:organization?/:idType/:id/:venueId?/stampCard/modify/:stampCardId", {
    ...OrganizationParameter,
    venueId: Yup.string(),
    stampCardId: Yup.string().required()
})

export const RewardModifyLocation = new Location("/manage/:organization?/:idType/:id/:venueId?/reward/modify/:rewardId/:rewardType", {
    ...OrganizationParameter,
    venueId: Yup.string(),
    rewardId: Yup.string().required(),
    rewardType: Yup.string().required()
})

export interface RewardModifyLocationParamsProps extends OrganizationParameterProps {
    venueId?: string;
    rewardId: string;
    rewardType: string;
  }

export const ProductsModifyLocation = new Location("/manage/:organization?/:idType/:id/:venueId?/products/modify", {
    ...OrganizationParameter,
    venueId: Yup.string(),
})

export const MemberStatusModifyLocation = new Location("/manage/:organization?/:idType/:id/:venueId?/statusType/:statusType/modify/:statusId", {
    ...OrganizationParameter,
    venueId: Yup.string(),
    statusType: Yup.string().required(),
    statusId: Yup.string().required()
})

export const MessagesLocation = new Location("/manage/:organization?/:venue?/:group?/messages/:idType/:id", OrganizationParameter, FilterQueryStrings)

export const RewardsLocation = new Location("/manage/:organization?/:venue?/:group?/rewards/:idType/:id", OrganizationParameter, FilterQueryStrings)

export const InvitationsLocation = new Location("/manage/:organization?/:venue?/:group?/invitations/:idType/:id", OrganizationParameter, FilterQueryStrings)

export const SendPushNotificationLocation = new Location("/manage/:organization?/:venue?/:group?/messages/:idType/:id/sendPushNotification/:messageId", {
    ...OrganizationParameter,
    messageId: Yup.string().required()
})

export const SendEmailLocation = new Location("/manage/:organization?/:venue?/:group?/messages/:idType/:id/sendEmail/:messageId", {
    ...OrganizationParameter,
    messageId: Yup.string().required()
})

export const ProfileLocation = new Location("/manage/profile")
export const ChangeOrgLocation = new Location("/changeOrg")
export const CreateOrganizationLocation = new Location("/changeOrg/createOrganization")
export const ManagePremiumLocation = new Location("/changeOrg/managePremium")

