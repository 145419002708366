import log from "loglevelnext"
import { observer } from "mobx-react"
import { useRef } from "react"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import { SelectGroup, TextInput, ValidationForm } from "react-bootstrap4-form-validation"
import { useTranslation } from "react-i18next"
import AlertMessage from "../../components/AlertMessage"
import { ModalBox } from "../../components/ModalBox"
import { useStores } from "../../hooks/UseStores"
import { IVenue } from "../../models/Venue"
import { cities } from "../../utils/constants"

const MEMBER_SCREEN_PUSH_NOTIFICATION_MODAL_ERROR = "memberScreenPushNotificationModal"




const MembersScreenModals = observer(() => {
  const statusManageInfoModalRef = useRef<any>(null)
  const sendMessageInfoModalRef = useRef<any>(null)
  const sendPushNotificationAckModalRef = useRef<any>(null)
  const sendPushNotificationModalRef = useRef<any>(null)
  const { t } = useTranslation()
  const { userSessionStore, api } = useStores()
  const venueNames = userSessionStore!.currentOrganization!.venues!.map((venue: IVenue) => venue.name)
  const defaultTargetAudience = userSessionStore!.isOrganizationContext() ? 'all' : userSessionStore!.selectedBranch!.name

  const onSubmitPushNotification = async (modalAction: any, e: any, formData: any) => {
    e.preventDefault()

    try {
      const requestSuccess = await api!.sendSimplePushNotificationRequest(
        `Target audience: ${formData.targetAudience}\n${formData.notificationText}`,
        undefined,
        MEMBER_SCREEN_PUSH_NOTIFICATION_MODAL_ERROR,
      )

      if (requestSuccess) {
        modalAction()
        return
      }
    } finally {
      log.debug("MemberScreen: Error while sending push notification")
    }
  }

  
  
  return <>
       <ModalBox getRef={(ref: any) =>  statusManageInfoModalRef.current = ref}
                title={t("memberScreen.modals.statusManageInfo.title")}
                renderContent={(action, cancel) => (
                  <>
                    <Modal.Body>{t("memberScreen.modals.statusManageInfo.text")}</Modal.Body>
                    <Modal.Footer>
                      <Button variant="primary" onClick={cancel}>
                        {t("memberScreen.modals.statusManageInfo.cancelButton")}
                      </Button>
                    </Modal.Footer>
                  </>
                )}/>

        <ModalBox getRef={(ref: any) => sendMessageInfoModalRef.current = ref}
                  title={t("memberScreen.modals.sendMessageInfo.title")}
                  renderContent={(action, cancel) => {
                    return (
                      <>
                        <Modal.Body>{t("memberScreen.modals.sendMessageInfo.text")}</Modal.Body>
                        <Modal.Footer>
                          <Button variant="primary" onClick={cancel}>
                            {t("memberScreen.modals.sendMessageInfo.cancelButton")}
                          </Button>
                        </Modal.Footer>
                      </>
                    )
                  }}/>

        <ModalBox getRef={(ref: any) => sendPushNotificationAckModalRef.current = ref.current}
                  title={t("memberScreen.modals.sendPushNotificationAck.title")}
                  renderContent={(action, cancel) => {
                    return (
                      <>
                        <Modal.Body>{t("memberScreen.modals.sendPushNotificationAck.text")}</Modal.Body>
                        <Modal.Footer>
                          <Button variant="primary" onClick={cancel}>
                            {t("memberScreen.modals.sendPushNotificationAck.cancelButton")}
                          </Button>
                        </Modal.Footer>
                      </>
                    )
                  }}/>

        <ModalBox getRef={(ref: any) => sendPushNotificationModalRef.current = ref.current}
                  title={t("memberScreen.modals.sendPushNotification.title")}
                  renderContent={(action, cancel) => {
                    return (
                      <>
                        <ValidationForm onSubmit={onSubmitPushNotification.bind(this, action)}>
                          <Modal.Body>
                            <p>{t("memberScreen.modals.sendPushNotification.text")}</p>
                            
                            <fieldset disabled={false}>
                              <div className="form-group row">
                                <div className="col-md-12 text-left">
                                  <label
                                    htmlFor="Message">{t("memberScreen.modals.sendPushNotification.messageLabel")}</label>
                                    <SelectGroup name="targetAudience" id="targetAudience"
                                        required errorMessage="Please select a target audience"
                                        defaultValue={defaultTargetAudience}
                                        >
                                        <option value="all">{t("allVenues")}</option>
                                        {cities.map(city => <option>{city}</option>)}
                                        {venueNames.map((venueName: string) => <option key={venueName} value={venueName}>{venueName}</option>)}
                                    </SelectGroup>

                                  <AlertMessage source={MEMBER_SCREEN_PUSH_NOTIFICATION_MODAL_ERROR}/>

                                </div>
                              </div>
                            </fieldset>

                            <fieldset disabled={false}>
                              <div className="form-group row">
                                <div className="col-md-12 text-left">
                                  <label
                                    htmlFor="Message">{t("memberScreen.modals.sendPushNotification.messageLabel")}</label>
                                  <TextInput name="notificationText" id="notificationText" required
                                             rows="5"
                                             maxLength="255"
                                             multiline
                                    //value={this.state.formData.email}
                                    //onChange={this.handleChange}
                                    //validator={validator.isEmail}
                                             errorMessage={{
                                               validator: t("validationErrorEmail"),
                                               required: t("fieldRequired"),
                                             }}
                                             successMessage={t("validationSuccess")}/>

                                  <AlertMessage source={MEMBER_SCREEN_PUSH_NOTIFICATION_MODAL_ERROR}/>

                                </div>
                              </div>
                            </fieldset>

                          </Modal.Body>
                          <Modal.Footer>
                            <Button variant="primary" onClick={cancel}>
                              {t("memberScreen.modals.sendPushNotification.cancelButton")}
                            </Button>
                            <Button
                              type="submit"
                              // @ts-ignore
                              variant="hentovihrea">
                              {t("memberScreen.modals.sendPushNotification.okButton")}
                            </Button>
                          </Modal.Footer>
                        </ValidationForm>
                      </>
                    )
                  }}/>
  </>
})

export default MembersScreenModals
