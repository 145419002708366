import { observer } from 'mobx-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FETCH_MEMBERS_SORTING } from '../../Api';
import AlertMessage from '../../components/AlertMessage';
import { useStores } from '../../hooks/UseStores';
import { IEventStore } from '../../models/EventStore';
import { IMemberStore } from '../../models/MemberStore';
import { colors } from '../../theme/colors';
import EventGuestListTable from './EventGuestListTable';
const EVENT_SCREEN_ERROR = "eventScreen";

const EventScreen = observer(() => {
  const { t } = useTranslation();
  const { eventId } = useParams<{ eventId: string }>();
  const { eventStore, memberStore } = useStores() as { eventStore: IEventStore; memberStore: IMemberStore };
  const [isInitMembersDataFetched, setIsInitMembersDataFetched] = useState(false);

  const fetchEventMembers = async (sorting = memberStore.filter.sorting) => {
   await memberStore.fetchMembers({
      venueEvent: eventId,
      columns: [
        FETCH_MEMBERS_SORTING.EVENTS_ACTIVATED,
        FETCH_MEMBERS_SORTING.EVENTS_TOTAL_TICKETS, 
        FETCH_MEMBERS_SORTING.EVENTS_TOTAL_CLOAKROOMS, 
        FETCH_MEMBERS_SORTING.EVENTS_TOTAL_SPENT, 
        FETCH_MEMBERS_SORTING.EVENTS_PURCHASE_DATE],
      sorting: sorting
    });
  };

  const fetchInitMembersData = async () => {
    try {
      memberStore.resetFilters();
      await fetchEventMembers();
      setIsInitMembersDataFetched(true);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    eventStore.getEventById(eventId);
    fetchInitMembersData();
  }, [eventId, eventStore, memberStore]);

  const event = eventStore.getEventById(eventId);

  const handleLoadMore = () => {
    memberStore.fetchMoreMembers();
  };

  const handleSort = (sorting: string) => {
    fetchEventMembers(sorting);
  };

  
  if (!event || !isInitMembersDataFetched) return  <div className="text-center mt-5">
  <Spinner animation="border" role="status" variant="primary">
  </Spinner>
</div>

  return (
    <Container fluid>
      <AlertMessage source={EVENT_SCREEN_ERROR} />
      <div className="text-center mb-4">
  <div style={{
    fontSize: 30,
    fontWeight: 'bold'
  }}>{event.title}</div>
  <div style={{
    color: colors.secondaryText
  }}>
    {event?.restaurantId?.name} | {moment(event?.startTime).format('DD.MM.YYYY')} | {moment(event?.startTime).format('HH:mm')}-{moment(event?.endTime).format('HH:mm')}
  </div>
</div>
    
          <div style={{
            fontSize: 24,
            fontWeight: 'bold',
            marginBottom: 10
          }}>
            {t('eventScreen.tableHeaders.visitors')}
          </div>

              <EventGuestListTable 
              memberStore={memberStore} 
              onLoadMore={handleLoadMore}   
              onSort={handleSort}
              currentSorting={memberStore.filter.sorting}
            />

    </Container>
  );
});

export default EventScreen;