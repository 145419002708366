import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as _ from "lodash"
import { inject, observer } from "mobx-react"
import "moment-duration-format"
import moment from "moment-timezone"
import React, { useCallback, useEffect, useState } from "react"
import Button from "react-bootstrap/Button"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Form from "react-bootstrap/Form"
import Nav from "react-bootstrap/Nav"
import Row from "react-bootstrap/Row"
import Table from "react-bootstrap/Table"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import Select from "react-select"
import { TableHeaderProps, useFilters, useSortBy, useTable } from "react-table"
import { FETCH_MEMBERS_SORTING } from "../Api"
import { MemberLocation } from "../Locations"
import { useUrlFilter } from "../hooks/FilterHooks"
import { useStores } from "../hooks/UseStores"
import blankProfileImage from "../img/blank-profile.png"
import { ILoyaltyCard } from "../models/LoyaltyCard"
import { ILoyaltyCardStore } from "../models/LoyaltyCardStore"
import { IMember, IMemberVenueMembership, IMemberVenueStatus, IStampCardItem } from "../models/Member"
import { FETCH_MEMBERS_COLUMNS, IMemberStore, IMembersFilter, IMembersStats } from "../models/MemberStore"
import { IUserSessionStore } from "../models/UserSessionStore"
import { colors } from "../theme/colors"
import { centsToFullCurrencyUnits } from "../utils"

interface CustomTableHeaderProps extends TableHeaderProps {
  onClick?: () => void;
}

const TABLE_TAB = {
  ALL_MEMBERS: "ALL_MEMBERS",
  STAMP_USAGE: "STAMP_USAGE",
  BIGGEST_SPENDERS: "BIGGEST_SPENDERS",
  TOP_BENEFIT_USERS: "TOP_BENEFIT_USERS",
}

const TABLE_COLUMN_ID = {
  NAME: 'name',
  STATUSES: 'statuses',
  PURCHASES: 'purchases',
  BENEFITS: 'benefits',
  LAST_SEEN: 'lastSeen',
  MEMBER_SINCE: 'memberSince',
  STAMP_CARDS_LIST: 'stampCardsList',
  STAMP_CARDS_TOTAL_STAMPS: 'stampCardsTotalStamps',
  STAMP_CARDS_TOTAL_REWARDS: 'stampCardsTotalRewards',
}

const COLUMN_ID_TO_SORT_TYPE_MAP = {
  [TABLE_COLUMN_ID.STATUSES]: FETCH_MEMBERS_SORTING.CARDS,
  [TABLE_COLUMN_ID.NAME]: FETCH_MEMBERS_SORTING.NAME,
  [TABLE_COLUMN_ID.MEMBER_SINCE]: FETCH_MEMBERS_SORTING.JOINED,
  [TABLE_COLUMN_ID.STAMP_CARDS_LIST]: FETCH_MEMBERS_SORTING.STAMP_CARDS_LIST,
  [TABLE_COLUMN_ID.STAMP_CARDS_TOTAL_STAMPS]: FETCH_MEMBERS_SORTING.STAMP_CARDS_TOTAL_STAMPS,
  [TABLE_COLUMN_ID.STAMP_CARDS_TOTAL_REWARDS]: FETCH_MEMBERS_SORTING.STAMP_CARDS_TOTAL_REWARDS,
};

const TAB_NAME_BY_SORTING = {
  [FETCH_MEMBERS_SORTING.JOINED]: TABLE_TAB.ALL_MEMBERS,
  [FETCH_MEMBERS_SORTING.PURCHASES]: TABLE_TAB.BIGGEST_SPENDERS,
  [FETCH_MEMBERS_SORTING.BENEFITS]: TABLE_TAB.TOP_BENEFIT_USERS,
}

const FILTER_BY_TAB_NAME = {
  [TABLE_TAB.ALL_MEMBERS]: FETCH_MEMBERS_SORTING.JOINED,
  [TABLE_TAB.BIGGEST_SPENDERS]: FETCH_MEMBERS_SORTING.PURCHASES,
  [TABLE_TAB.TOP_BENEFIT_USERS]: FETCH_MEMBERS_SORTING.BENEFITS,
  [TABLE_TAB.STAMP_USAGE]: FETCH_MEMBERS_SORTING.CARDS,
};

// @ts-ignore
function MemberNameCell(cellData) {
  const value = cellData.cell.value
  return <div className="text-left d-flex align-items-center hover-underline" style={{marginLeft: 30}}>
    <div className="" style={{
      position: 'relative',
      width: 40
    }}>
        <img alt="user" src={value.images?.small || blankProfileImage} style={{
          width: 35,
          height: 35,
          borderRadius: '50%',
          position: 'absolute',
          left: -10,
          top: -17
        }}/>
      
      
    </div>
    <div>{value.name}</div>
  </div>
}

// @ts-ignore
function VenuesCell(cellData) {

  const valueArray = cellData.cell.value
  if (valueArray.length === 0) return "-"
  return <div
    className="text text-nowrap font-size-normal">{valueArray.map((membership: IMemberVenueMembership) => membership.id.name).sort().map((venueName: string) => {
    return <div key={venueName}>{venueName}</div>
  })}</div>
}

// @ts-ignore
function MemberSinceCell(cellData) {
  const value = cellData.cell.value
  //const daysMember = moment.utc().diff(moment.utc(value), "days")
  return value ?
    moment.utc(value).tz("Europe/Helsinki").format("DD.MM.YYYY")
    //+ " " + moment.duration(daysMember, "day").format("(d [days])", {trim: false})
    : "-"
}

// @ts-ignore
function LastSeenCell(cellData) {
  const value = cellData.cell.value
  return value ? moment.utc(value).tz("Europe/Helsinki").format("DD.MM.YYYY") : "Never"
}

// @ts-ignore
function StatusCell(cellData) {
  const valueArray: Array<IMemberVenueStatus> = cellData.cell.value

  if (valueArray.length === 0) return "-"
  return valueArray.map((memberVenueStatus: IMemberVenueStatus) => {
    const statusName = memberVenueStatus.id.localeVenueTitle

    const shouldExternalIdentifierBeShown = memberVenueStatus.externalIdentifier && memberVenueStatus?.id?.flags?.showExternalIdentifier

    return (
      <div key={memberVenueStatus.id.id}>
        <div className="text-primary text-nowrap font-size-normal">{statusName} 
        {shouldExternalIdentifierBeShown &&

        <span className="font-size-small pl-1">(#{memberVenueStatus.externalIdentifier})</span>
        }
        <span
          className="font-size-small pl-1">{memberVenueStatus.validThrough && moment.utc(memberVenueStatus.validThrough).tz("Europe/Helsinki").format("(DD.MM.YYYY)")}</span>
        </div>
      </div>
    )
  })
}

// @ts-ignore
function RewardsCell(cellData) {
  const value: number | undefined | null = cellData.cell.value

  if (!value) return "-"

  return (
      <div className="text-primary text-nowrap font-size-normal font-weight-bold">{value} 
      
      </div>
  )
}

// @ts-ignore
function StampsCell(cellData) {
  const value: number | undefined | null = cellData.cell.value

  if (!value) return "-"

  return (
      <div className="text-primary text-nowrap font-size-normal font-weight-bold">{value} 
      
      </div>
  )
}

// @ts-ignore
function StampCardCell(cellData) {
  const valueArray: Array<IStampCardItem> | undefined | null = cellData.cell.value

  if (!valueArray || valueArray.length === 0) return "-"
  return valueArray.map((stampCardItem: IStampCardItem) => {

    if (!stampCardItem?.id) {
      return "-"
    }

    const stampCardName = stampCardItem?.loyaltyCardId?.localeName()

    const stampsForAReward = 
      stampCardItem?.loyaltyCardId?.stampsCountForAReward()  ||  '-' ;

    return (
      <div key={stampCardItem.id}>
        <div className="text-primary text-nowrap font-size-normal">{stampCardName} 
     
        <span
          className="font-size-small pl-1">{stampCardItem.stampsCount}/{stampsForAReward}</span>
        </div>
      </div>
    )
  })
}



function PurchasesCell(cellData: any) {
  const value = cellData.cell.value
  return <span style={{
    color: colors.primaryText,
    fontWeight: 'bold'
  }}>{`${centsToFullCurrencyUnits(value)}€`}</span>
}

function BenefitsCell(cellData: any) {
  const value = cellData.cell.value
  return <span style={{
    color: colors.primaryText,
    fontWeight: 'bold'
  }}>{value}</span>
}



// @ts-ignore
function RenderMemberTable({columns, data, initialRowCount, stats, filter, memberStore, loyaltyCardStore, selectedTab, setSelectedTab, shouldShowMoreMembersButton, hasAnyBenefitActivations, hasAnySales}) {
  const {t} = useTranslation()
  const [visibleRowCount, setVisibleRowCount] = useState(100)
 
 
  const history = useHistory()
  const {userSessionStore} = useStores()



  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 20,
    }),
    [],
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: {sortBy},
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        hiddenColumns: ["lastSeen"],
        // sortBy: [{id: "memberSince", desc: true}],
      },
      disableSortRemove: true,
    },
    useFilters,
    useSortBy,
  )


  React.useEffect(() => {
    setVisibleRowCount(initialRowCount || 100)
  }, [sortBy])


  React.useEffect(() => {
    setTab(TABLE_TAB.ALL_MEMBERS)
  }, [userSessionStore?.selectedBranch?.id])

  const visibleRows = rows.slice(0, data.length)

  const setTab = async (tabName: string | undefined | null) => {
    if (selectedTab === tabName || !tabName) return;
  
    const filterTabName = FILTER_BY_TAB_NAME[tabName];
    if (filterTabName) {

      if (filterTabName === FILTER_BY_TAB_NAME.STAMP_USAGE) {
        await memberStore?.fetchMembers({
          columns: [FETCH_MEMBERS_COLUMNS.STAMP_CARDS_LIST, FETCH_MEMBERS_COLUMNS.STAMP_CARDS_TOTAL_REWARDS, FETCH_MEMBERS_COLUMNS.STAMP_CARDS_TOTAL_STAMPS],
          sorting: FETCH_MEMBERS_SORTING.STAMP_CARDS_LIST,
          stampCardsIds:  loyaltyCardStore?.loyaltyCards.map((card: ILoyaltyCard)  => card.id) 
        });
      }

      if (filterTabName === FILTER_BY_TAB_NAME.ALL_MEMBERS) {
        await memberStore?.fetchMembers({
          sorting: FETCH_MEMBERS_SORTING.JOINED,
          columns: [],
          stampCardsIds: [],
        });
      }

      setSelectedTab(tabName);
    }
  };

  const selectedVenueId = userSessionStore?.selectedBranch?.id
  const isOrganizationContext = userSessionStore?.isOrganizationContext()
  const selectedVenueLoyaltyCards = isOrganizationContext ? loyaltyCardStore?.loyaltyCards :  loyaltyCardStore?.loyaltyCards?.filter((card: ILoyaltyCard) => card?.restaurantId?.id === selectedVenueId)

  const hasAnyLoyaltyCards = selectedVenueLoyaltyCards && selectedVenueLoyaltyCards.length > 0

  const handleSort = async (column: any) => {
    const sortByNameForServerSideSorting = COLUMN_ID_TO_SORT_TYPE_MAP[column.id];
    if (sortByNameForServerSideSorting) {
      const currentSorting = memberStore?.filter.sorting;
      let newSorting;

      if (currentSorting === sortByNameForServerSideSorting) {
        newSorting = `-${sortByNameForServerSideSorting}`;
      } else if (currentSorting === `-${sortByNameForServerSideSorting}`) {
        newSorting = sortByNameForServerSideSorting;
      } else {
        newSorting = sortByNameForServerSideSorting;
      }

      await memberStore?.fetchMembers({ sorting: newSorting });
    }
  };

  // @ts-ignore
  return (
    <>
      <Card className="mb-4">
        {/*        <Card.Header as="h6"><FontAwesomeIcon className="mr-2"
                                              icon={["fal", "filter"]}/>{t("benefitScreen.sections.filters")}
        </Card.Header>*/}
        <Card.Body className="px-0">
          <Container>
            <Row>
              {headerGroups.map(headerGroup => (
                headerGroup.headers.map((column: any) => (
                  column.canFilter && column.Filter ?
                    <React.Fragment key={column.id}>{column.render("Filter")}</React.Fragment>
                    : null
                ))
              ))}
            </Row>
          </Container>

        </Card.Body>
      </Card>

      <div className="mb-2 text-center font-size-medium font-heading font-weight-light">
        {t("memberScreen.tableRowSummary", {
          visibleRowCount: data.length,
          totalRowCount: memberStore?.filter.totalMembers,
        })}
      </div>

      <Container>
        <Row>
          <Col>
            <Nav variant="tabs" activeKey={selectedTab} onSelect={(name) => setTab(name)}>
              <Nav.Item>
                <Nav.Link eventKey={TABLE_TAB.ALL_MEMBERS}><FontAwesomeIcon className="mr-2"
                                                                icon={["fal", "user-friends"]}/>{t("memberScreen.allMembers")}
                </Nav.Link>
              </Nav.Item>
              {hasAnyLoyaltyCards > 0 &&
                    <Nav.Item>
                    <Nav.Link eventKey={TABLE_TAB.STAMP_USAGE}><FontAwesomeIcon className="mr-2"
                                                                  icon={["fal", "gift-card"]}/>{t("memberScreen.stampUsage")}
                    </Nav.Link>
                  </Nav.Item>
              }
          
              {/* {hasAnySales &&
                <Nav.Item>
                  <Nav.Link eventKey={TABLE_TAB.BIGGEST_SPENDERS}><FontAwesomeIcon className="mr-2"
                                                                icon={["fal", "usd-circle"]}/>{t("memberScreen.biggestSpenders")}
                  </Nav.Link>
                </Nav.Item>
              }
              {hasAnyBenefitActivations &&
                <Nav.Item>
                  <Nav.Link eventKey={TABLE_TAB.TOP_BENEFIT_USERS}><FontAwesomeIcon className="mr-2"
                                                            icon={["fal", "star"]}/>{t("memberScreen.topBenefitUsers")}
                  </Nav.Link>
                </Nav.Item>
              } */}
              
            </Nav>
          </Col>
        </Row>
      </Container>

      <Card className="m-0" style={{ borderTopLeftRadius: 0, borderWidth: 0}}>
        <div className="rounded-sm">
          <Table className="memberTable p-0 m-0" striped hover {...getTableProps()}>
            <thead className="text-primary" style={{backgroundColor: ""}}>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()} style={{
                MozUserSelect: "none",
                WebkitUserSelect: "none",
              }}>
                {headerGroup.headers.map(column => {
                  const headerProps: CustomTableHeaderProps = column.getHeaderProps();
                  const sortByNameForServerSideSorting = COLUMN_ID_TO_SORT_TYPE_MAP[column.id];

                  if (sortByNameForServerSideSorting) {
                    headerProps.onClick = () => handleSort(column);
                  }

                  const isColumnSorted = memberStore?.filter.sorting === sortByNameForServerSideSorting ||
                    memberStore?.filter.sorting === `-${sortByNameForServerSideSorting}`;
                  const isSortedDesc = memberStore?.filter.sorting === `-${sortByNameForServerSideSorting}`;
                
                  return (
                    <th {...headerProps} style={{
                      MozUserSelect: "none",
                      WebkitUserSelect: "none",
                      whiteSpace: "nowrap",
                      width: column.width,
                      borderWidth: 0,
                      cursor: sortByNameForServerSideSorting ? 'pointer' : 'default',
                    }}>
                      {column.render("Header")}
                      {sortByNameForServerSideSorting && (
                        <span>
                          {isColumnSorted
                            ? isSortedDesc
                              ? <FontAwesomeIcon className="ml-2" icon={["fas", "caret-down"]}/>
                              : <FontAwesomeIcon className="ml-2" icon={["fas", "caret-up"]}/>
                            : null}
                        </span>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {visibleRows.map(
              (row, i) => {
                prepareRow(row)
                return (
                  <tr style={{cursor: "pointer"}} {...row.getRowProps()} onClick={
                    () => {
                      // @ts-ignore
                      history.push(MemberLocation.toUrl({
                        ...userSessionStore!.selectedBranch!.urlFields(),
                        // @ts-ignore
                        memberId: row!.original!.id!
                      }))
                    }}>
                    {row.cells.map(cell => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      )
                    })}
                  </tr>
                )
              },
            )}
            </tbody>
          </Table>
        </div>
      </Card>
      <div className="pt-3 text-center">
        <p className="mb-3 font-size-medium">
          {t("memberScreen.tableRowSummary", {
            visibleRowCount: data.length,
            totalRowCount: memberStore?.filter.totalMembers,
          })}
        </p>
        {shouldShowMoreMembersButton &&
        <Button size="sm" variant="secondary" onClick={async () => {
          // const rowsLeft = rows.length - visibleRowCount
          // setVisibleRowCount(visibleRowCount + (rowsLeft < 100 ? rowsLeft : 100))
          await memberStore?.fetchMoreMembers();
        }}>
            <FontAwesomeIcon className="mr-2" icon={["fal", "chevrons-down"]}/>
          {t("memberScreen.showMore")}</Button>
        }
      </div>
    </>
  )
}

// @ts-ignore
function VenueMultiselectFilter({column: {filterValue, setFilter, preFilteredRows, id}, data}) {
  const {t} = useTranslation()
  const {urlFilter, setUrlFilter} = useUrlFilter("venues")
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options: Array<{ value: string, label: string }> = []
    data.forEach((row: any) => {
      const memberships: Array<IMemberVenueMembership> = row.venueMemberships
      memberships.forEach((membership: IMemberVenueMembership) => {
        const venueOption = {value: membership!.id.id, label: membership.id.name!}
        if (!_.some(options, (item: any) => _.isEqual(item, venueOption))) {
          options.push(venueOption)
        }
      })
    })
    // @ts-ignore
    return [...options.values()]
  }, [data])

  let values = options.filter((option) => {
    // @ts-ignore
    return urlFilter && urlFilter.indexOf(option.value) !== -1
  })

  useEffect(() => {
    setFilter(urlFilter)
    // eslint-disable-next-line
  }, [urlFilter])

  return (
    <Col className="pr-4">
      <div>
        <Select
          placeholder={t("benefitScreen.filters.selectVenues")}
          onChange={(entry: any) => {
            const values = entry && entry.length > 0 ? entry.map((o: any) => {
              return o.value
            }) : undefined
            setFilter(values)
            setUrlFilter(values)
          }}
          isMulti={true}
          options={options}
          noOptionsMessage={() => t("noOptions")}
          value={values}
          className="react-select-container"
          classNamePrefix="react-select"
        />
      </div>
    </Col>
  )
}

// @ts-ignore
function StampCardMultiselectFilter({column: {filterValue, setFilter, preFilteredRows, id}, data,  stampCards, selectedStampCardsIds, fetchMembers, isSingleVenue}) {
  const {t} = useTranslation()
  const {urlFilter, setUrlFilter} = useUrlFilter("stampCards")
  const [selectedStampCards, setSelectedStampCardsIds] = useState(selectedStampCardsIds)

  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options: Array<{ value: string, label: string }> = []
      const cards: Array<ILoyaltyCard> = stampCards
      cards.forEach((card: ILoyaltyCard) => {

        const venueName = isSingleVenue ? "" : card.restaurantId.name + ": "
        const stampCardOption = {value: card.id, label: venueName + card.localeName()}
        if (!_.some(options, (item: any) => _.isEqual(item, stampCardOption))) {
          options.push(stampCardOption)
        }
      })
    // @ts-ignore
    return [...options.values()]
  }, [stampCards])

  let values = options.filter((option) => {
    // @ts-ignore
    return selectedStampCards && selectedStampCards.indexOf(option.value) !== -1
  })

  useEffect(() => {
    setFilter(urlFilter)
    // eslint-disable-next-line
  }, [urlFilter])

  return (
    <Col className="pr-4">
      <div>
        <Select
          placeholder={t("memberScreen.filters.selectStatuses")}
          onChange={async (entry: any) => {
            const values = entry && entry.length > 0 ? entry.map((o: any) => {
              return o.value
            }) : []

            const isNoStampCardSelected = values.length === 0
            const updatedValuesNoEmptyPossible = isNoStampCardSelected ? options.map((o: any) => o.value) : values

            setSelectedStampCardsIds(values)
            await fetchMembers({
              stampCardsIds: values
            })
   
          }}
          isMulti={true}
          options={options}
          noOptionsMessage={() => t("noOptions")}
          value={values}
          className="react-select-container"
          classNamePrefix="react-select"
        />
      </div>
    </Col>
  )
}

// @ts-ignore
function StatusMultiselectFilter({column: {filterValue, setFilter, preFilteredRows, id}, data, venueStatuses, selectedVenueStatuses, fetchMembers}) {
  const {t} = useTranslation()
  const {urlFilter, setUrlFilter} = useUrlFilter("statuses")
  const [selectedStatuses, setSelectedStatuses] = useState(selectedVenueStatuses)

  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options: Array<{ value: string, label: string }> = []
      const statuses: Array<IMemberVenueStatus> = venueStatuses
      statuses.forEach((status: IMemberVenueStatus) => {
        const statusOption = {value: status.id.id, label: status.id.restaurantId.name + " " + status.id.localeTitle}
        if (!_.some(options, (item: any) => _.isEqual(item, statusOption))) {
          options.push(statusOption)
        }
      })
    // @ts-ignore
    return [...options.values()]
  }, [venueStatuses])

  let values = options.filter((option) => {
    // @ts-ignore
    return selectedStatuses && selectedStatuses.indexOf(option.value) !== -1
  })

  useEffect(() => {
    setFilter(urlFilter)
    // eslint-disable-next-line
  }, [urlFilter])

  return (
    <Col className="pr-4">
      <div>
        <Select
          placeholder={t("memberScreen.filters.selectStatuses")}
          onChange={async (entry: any) => {
            const values = entry && entry.length > 0 ? entry.map((o: any) => {
              return o.value
            }) : []

            setSelectedStatuses(values)
            await fetchMembers({
              venueStatuses: values
            })
   
          }}
          isMulti={true}
          options={options}
          noOptionsMessage={() => t("noOptions")}
          value={values}
          className="react-select-container"
          classNamePrefix="react-select"
        />
      </div>
    </Col>
  )
}


// @ts-ignore
function NameColumnFilter({column}) {
  const {filterValue, preFilteredRows, setFilter, fetchMembers, searchByName} = column
  const {t} = useTranslation()
  // const {urlFilter, setUrlFilter} = useUrlFilter("name")
  const [searchByNameInput, setSearchByNameInput] = useState(searchByName)
  const [init, setInit] = useState(false)

  const onNameChangeUrlFilter = async (newInput: string) => {
    await fetchMembers({
      name: newInput
    })
  }
  
  const delayUrlFilter = useCallback(_.debounce(onNameChangeUrlFilter, 500, {
    trailing: true
  }), [])
  
  const onNameChange = (newInput: string) => {
    setFilter(newInput)
    delayUrlFilter(newInput)
  }
  

  return (
    <Col className="pr-4">
      <div className="input-group">
        <Form.Control
          type="text"
          placeholder={t("memberScreen.filters.searchName")}
          value={searchByNameInput || ""}
          onChange={(e: any) => {
            // setInit(true)
            setSearchByNameInput(e.target.value)
            onNameChange(e.target.value)
          }
          }
        />
        <Button className="ml-2" disabled={searchByNameInput === ''} variant={"outline-primary"}
                onClick={() => {
                  setSearchByNameInput("")
                  onNameChange("")
                }}>{t("clear")}</Button>
      </div>
    </Col>
  )
}

type MemberTableProps = {
  data: IMember[]
  showStatuses: boolean
  initialRowCount?: number
  memberStore?: IMemberStore
  stats?: IMembersStats
  filter?: IMembersFilter
  loyaltyCardStore?: ILoyaltyCardStore
  userSessionStore?: IUserSessionStore
  hasAnyBenefitActivations?: boolean
  hasAnySales?: boolean
}

 function MemberTable(props: MemberTableProps) {
  const {t} = useTranslation()

  // if more tabs are added, this should be refactored into a mobx selectedTab or alike
  const isStampUsageTabSelected = props.memberStore?.filter?.columns.includes(FETCH_MEMBERS_COLUMNS.STAMP_CARDS_LIST) || 
  props.memberStore?.filter?.columns.includes(FETCH_MEMBERS_COLUMNS.STAMP_CARDS_TOTAL_REWARDS) ||
   props.memberStore?.filter?.columns.includes(FETCH_MEMBERS_COLUMNS.STAMP_CARDS_TOTAL_STAMPS)

  // @ts-ignore
  const INIT_TAB_NAME = isStampUsageTabSelected ? TABLE_TAB.STAMP_USAGE 
    : TABLE_TAB.ALL_MEMBERS
  const [selectedTab, setSelectedTab] = useState(INIT_TAB_NAME)
 


const loyaltyCards = props.userSessionStore!.isOrganizationContext() ? 
props.loyaltyCardStore?.loyaltyCards : 
props.loyaltyCardStore?.loyaltyCards?.filter(card => card.restaurantId.id === props.userSessionStore!.selectedBranch!.id)


  const columns = React.useMemo(
    () => [
      {
        Header: t("memberScreen.tableHeaders.name"),
        accessor: (row: IMember) => {
          return {
            name: row.name,
            images: row.profileImages,  
          }
        },
        sortType: "basic",
        id: TABLE_COLUMN_ID.NAME,
        width: "35%",
        Filter: NameColumnFilter,
        fetchMembers: props.memberStore?.fetchMembers,
        searchByName: props.memberStore?.filter.searchByName,
        Cell: MemberNameCell,
      },
      {
        Header: t("memberScreen.tableHeaders.memberships"),
        accessor: (row: IMember) => {
          return row.venueMemberships
        },
        id: "memberships",
        //width: "20%",
        Cell: VenuesCell,
        disableSortBy: true,
        // Filter: (rest: any) => VenueMultiselectFilter({...rest, data: props.data}),
        // sortType: (a: any, b: any, direction: any) => {
        //   if (a.values.memberships.length === b.values.memberships.length) {
        //     return (
        //       a.values.memberships.map((membership: IMemberVenueMembership) => membership.id.name).sort().join(", ") <
        //       b.values.memberships.map((membership: IMemberVenueMembership) => membership.id.name).sort().join(", "))
        //       ? 1 : -1
        //   }
        //   return a.values.memberships.length > b.values.memberships.length ? 1 : -1
        // },
        // filter: (rows: any, id: any, filterValue: Array<string>) => {
        //   return rows.filter((row: any) => {
        //     const rowValue: Array<IMemberVenueMembership> = row.values[id]
        //     return _.some(rowValue, (status: IMemberVenueMembership) =>
        //       _.some(filterValue, (venueId: string) => venueId === status.id.id),
        //     )
        //   })
        // },
      },
      ...(selectedTab === TABLE_TAB.ALL_MEMBERS ? [{
        Header: t("memberScreen.tableHeaders.statuses"),
        accessor: (row: IMember) => {
          return row.venueStatuses
        },
        //width: 400,
        sortType: "basic",
        id: TABLE_COLUMN_ID.STATUSES,
        Cell: StatusCell,
        Filter: (rest: any) => StatusMultiselectFilter({
          ...rest, 
          data: props.data, 
          selectedVenueStatuses: props.memberStore!.filter.venueStatuses, 
          venueStatuses: props.memberStore!.stats?.venueStatuses,
          fetchMembers: props.memberStore?.fetchMembers
        }),

      }] : []),
      ...(selectedTab === TABLE_TAB.STAMP_USAGE ? [
        {
        Header: t("memberScreen.tableHeaders.stampCards"),
        accessor: (row: IMember) => {
          return row.incompleteStampCardsList()
        },
        //width: 400,
        sortType: "basic",
        id: TABLE_COLUMN_ID.STAMP_CARDS_LIST,
        Cell: StampCardCell,
        Filter: (rest: any) => StampCardMultiselectFilter({
          ...rest, 
          data: props.data, 
          selectedStampCardsIds: props.memberStore!.filter.stampCardsIds, 
          stampCards: loyaltyCards, 
          isSingleVenue: props.userSessionStore!.currentOrganization!.isSingleVenue(),
          fetchMembers: props.memberStore?.fetchMembers
        }),

      },
      {
        Header: t("memberScreen.tableHeaders.stamps"),
        accessor: (row: IMember) => {
          return row.stampCardsTotalStamps
        },
        //width: 400,
        sortType: "basic",
        id: TABLE_COLUMN_ID.STAMP_CARDS_TOTAL_STAMPS,
        Cell: StampsCell,

      },
      {
        Header: t("memberScreen.tableHeaders.rewards"),
        accessor: (row: IMember) => {
          return row.stampCardsTotalRewards
        },
        //width: 400,
        sortType: "basic",
        id: TABLE_COLUMN_ID.STAMP_CARDS_TOTAL_REWARDS,
        Cell: RewardsCell,
      }] : []),

      ...(selectedTab === TABLE_TAB.BIGGEST_SPENDERS ? [{
        Header: t("purchases"),
        accessor: (row: IMember) => {
          return row.totalPurchases
        },
        id: TABLE_COLUMN_ID.PURCHASES,
        Cell: PurchasesCell,
      }] : []),
      ...(selectedTab === TABLE_TAB.TOP_BENEFIT_USERS ? [{
        Header: t("benefits"),
        accessor: (row: IMember) => {
          return row.totalBenefitsActivations
        },
        id: TABLE_COLUMN_ID.BENEFITS,
        Cell: BenefitsCell,
      }] : []),
      {
        Header: t("memberScreen.tableHeaders.memberSince"),
        accessor: (row: IMember) => {
          return row.venueMemberships.length > 0 ? _.minBy(row.venueMemberships, "memberSince").memberSince : null
        },
        Cell: MemberSinceCell,
        id: TABLE_COLUMN_ID.MEMBER_SINCE,
        //width: "300,
      },
      {
        Header: t("memberScreen.tableHeaders.lastSeen"),
        accessor: "lastSeen",
        id: TABLE_COLUMN_ID.LAST_SEEN,
        Cell: LastSeenCell,
      },
    ],
    [props.data, t, selectedTab],
  )

  const filteredColumns = (!props.userSessionStore!.isOrganizationContext() ?
    columns.filter(column => column.id !== "memberships") :
    columns)
    .filter(column => !props.showStatuses ? column.id !== "statuses" : true)


    const sortedData = () => {
      if (selectedTab === TABLE_TAB.ALL_MEMBERS) {
        return _.orderBy(props.data, [(item: IMember) => {
          const latestMembership = _.maxBy(item.venueMemberships, 'memberSince');
          return latestMembership ? latestMembership.memberSince : null;
        }], ['desc']);
      }
  
      if (selectedTab === TABLE_TAB.BIGGEST_SPENDERS) {
        return _.orderBy(props.data, ["totalPurchases"], ["desc"])
      }
  
      if (selectedTab === TABLE_TAB.TOP_BENEFIT_USERS) {
        return _.orderBy(props.data, ["totalBenefitsActivations"], ["desc"])
      }

      return props.data
    }

  const membersData = props.data


const filterAndReorderMembersWithoutVenueMemberships = () => {
  const totalMembers = props.memberStore?.filter?.totalMembers || 0;

  const membersWithoutVenueMemberships = membersData.filter(member => member.venueMemberships.length === 0);

  const membersWithVenueMemberships = membersData.filter(member => member.venueMemberships.length > 0);

  const isNotAllMembersForFilterCriteriaAvailable = membersData.length < totalMembers

  if (isNotAllMembersForFilterCriteriaAvailable) {
    return membersWithVenueMemberships;
  } else {
    return [...membersWithVenueMemberships, ...membersWithoutVenueMemberships];
  }
};

const filteredOrderedMembersWithoutVenueMemberships = filterAndReorderMembersWithoutVenueMemberships()


  if (!membersData) {
    return null
  }

  return (
    <RenderMemberTable 
      columns={filteredColumns} 
      stats={props.stats} 
      filter={props.filter} 
      shouldShowMoreMembersButton={props.memberStore!.shouldShowMoreMembersButton}
      setSelectedTab={setSelectedTab}
      selectedTab={selectedTab}
      initialRowCount={membersData?.length} 
      hasAnySales={props.hasAnySales}
      loyaltyCardStore={props.loyaltyCardStore}
      hasAnyBenefitActivations={props.hasAnyBenefitActivations}
      data={filteredOrderedMembersWithoutVenueMemberships} 
      memberStore={props.memberStore}/>
  )
}

export default inject("userSessionStore", "loyaltyCardStore", "memberStore", "rewardStore")(observer(MemberTable));