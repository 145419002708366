import {flow, getEnv, Instance, types} from "mobx-state-tree"
import {Event} from "./Event"
import log from "loglevelnext"
import * as _ from "lodash"
import {Venue} from "./Venue"

export const EventSummary = types.model("EventSummary")
  .props({
    restaurantId: types.reference(Venue),
    visitors: types.number,
    visitors30Days: types.number,
    revenue: types.number,
    revenue30Days: types.number,
    entrances: types.number,
    entrances30Days: types.number,
    cloakrooms: types.number,
    cloakrooms30Days: types.number,
  })

export const EventStore = types.model("EventStore")
  .props({
    index: types.map(types.map(types.number)),
    summaries: types.map(EventSummary),
    events: types.optional(types.array(Event), []),
  }).actions(self => ({
    fetchIndex: flow(function* fetchIndex(requestSource: string = "init") {
      log.debug("EventStore: fetchIndex")
      const eventsIndex = yield getEnv(self).api.fetchEventsIndex()

      self.summaries.replace(eventsIndex.data.summary)
      self.index.replace(eventsIndex.data.index)
    }),
  })).actions(self => ({
    fetchEvents: flow(function* fetchEvents(params: { range: any }, requestSource: string = "init") {
      const response = yield getEnv(self).api.fetchEvents(undefined, params)
      if (response.modified) {
        self.events.replace(response.payload.data)
      }
      return response.etag
    }),
  })).actions(self => ({
    fetchUpcoming: flow(function* fetchUpcoming(initial: boolean = false) {
    }),
    fetchPast: flow(function* fetchUpcoming(initial: boolean = false) {
    }),
  })).actions(self => ({
    fetchData: flow(function* fetchData(requestSource: string = "init") {
      log.debug("EventStore: fetchData")
      yield self.fetchIndex()
      const range = _.mapValues(Object.fromEntries(self.index), function (o: any) {
        const months = _.keys(Object.fromEntries(o)).sort()
        return [_.min(months), _.max(months)]
      })
      if (!_.isEmpty(range)) return yield self.fetchEvents({range: range})
    }),
    resetData() {
      log.debug("EventStore: resetData")
      self.events.clear()
      self.index.clear()
      self.summaries.clear()
    },
  })).views(self => ({
    hasMoreUpcoming() {
      return false
    },
    hasMorePast() {
      return false
    },
    getEventById(eventId: string) {
      return self.events.find(event => event.id === eventId)
    }
  }))

export type IEventStore = Instance<typeof EventStore>
