import {Instance, types} from "mobx-state-tree"
import {IVenue, Venue} from "./Venue"
import {
  IVenueStatus,
  VENUE_STATUS_PAYMENT_TYPE_FIXED,
  VENUE_STATUS_PAYMENT_TYPE_FLEXIBLE,
  VENUE_STATUS_TYPE_PRIVATE,
  VENUE_STATUS_TYPE_PURCHASE,
  VenueStatus
} from "./MemberStore"
import log from "loglevelnext"
import moment from "moment-timezone"
import { Benefit } from "./Benefit"
import { Event } from "./Event"
import { Product } from "./Product"
import { LoyaltyCard } from "./LoyaltyCard"

export const MemberVenueMembership = types.model("MemberVenueMembership")
  .props({
    id: types.reference(Venue),
    memberSince: types.string,
    email: types.maybeNull(types.string),
  })

export type IMemberVenueMembership = Instance<typeof MemberVenueMembership>

export const MEMBER_VENUE_STATUS_ORIGIN_PURCHASE = 'PURCHASE'


export const MemberVenueStatus = types.model("MemberVenueStatus")
  .props({
    id: types.reference(types.late(() => VenueStatus)),
    validFrom: types.maybeNull(types.string),
    validThrough: types.maybeNull(types.string),
    externalIdentifier: types.maybeNull(types.string),
    userVenueStatusId: types.maybeNull(types.string),
    origin: types.optional(types.model({
      type: types.maybe(types.string)
    }), {})
  })

  export const VenueBenefitsActivation = types.model("VenueBenefitsActivation")
  .props({
    id: types.reference(types.late(() => Benefit)),
    actionDate: types.maybe(types.string),
  })

  export const VenueEventActivation = types.model("VenueEventActivation")
  .props({
    id: types.reference(types.late(() => Event)),
    actionDate: types.maybe(types.string),
  })

  export const VenueEventPurchase = types.model("VenueEventPurchase")
  .props({
    id: types.reference(types.late(() => Event)),
    actionDate: types.maybe(types.string),
  })

  export const VenueMemberships = types.model("VenueMemberships")
  .props({
    id: types.reference(types.late(() => Venue)),
    actionDate: types.maybe(types.string),
  })

  export const VenueProductActivation = types.model("VenueProductActivation")
  .props({
    id: types.reference(types.late(() => Product)),
    actionDate: types.maybe(types.string),
  })

  export const VenueProductPurchase = types.model("VenueProductPurchase")
  .props({
    id: types.reference(types.late(() => Product)),
    actionDate: types.maybe(types.string),
  })

  export const VenueStatusesTimeline = types.model("VenueStatusesTimeline")
  .props({
    id: types.reference(types.late(() => VenueStatus)),
    actionDate: types.maybe(types.string),
  })

  export const VenueStampGathering = types.model("VenueStampGathering", {
    id: types.reference(types.late(() => LoyaltyCard)),
    actionDate: types.maybe(types.string),
    restaurantId: types.reference(types.late(() => Venue)),
  });
  

  export const MemberTimeline = types.model("MemberTimeline")
  .props({
    id: types.identifier,
    totalBenefitsActivations: types.maybe(types.number),
    totalPurchases:  types.maybe(types.number),
    venueBenefitsActivations: types.maybe(types.array(VenueBenefitsActivation)),
    venueEventActivation: types.maybe(types.array(VenueEventActivation)),
    venueEventPurchase: types.maybe(types.array(VenueEventPurchase)),
    venueMemberships: types.maybe(types.array(VenueMemberships)),
    venueProductActivation: types.maybe(types.array(VenueProductActivation)),
    venueProductPurchase: types.maybe(types.array(VenueProductPurchase)),
    venueStatuses:  types.maybe(types.array(VenueStatusesTimeline)),
    venueStampGathering: types.optional(types.array(VenueStampGathering), []),
  })

  const ProfileImages = types.model("ProfileImages")
  .props({
    large: types.maybe(types.string),
    small: types.maybe(types.string),
  })

  export const StampCardItem = types.model("StampCardItem").props({
    id: types.identifier,
    loyaltyCardId: types.maybeNull(types.safeReference(types.late(() => LoyaltyCard))),
    stampsCount: types.number,
    status: types.enumeration("StampCardStatus", ["FULL", "INCOMPLETE"])
  });

  export type IStampCardItem = Instance<typeof StampCardItem>

export type IMemberTimeline = Instance<typeof MemberTimeline>
export type IMemberVenueStatus = Instance<typeof MemberVenueStatus>



export const Member = types.model("Member")
  .props({
    id: types.identifier,
    name: types.maybe(types.string),
    email: types.maybeNull(types.string),
    birthDay: types.maybeNull(types.string),
    profileImages: types.maybeNull(ProfileImages),
    gender: types.maybeNull(types.array(types.string)),
    totalBenefitsActivations: types.maybeNull(types.number),
    totalPurchases: types.maybeNull(types.number),
    lastSeen: types.maybe(types.string),
    venueMemberships: types.array(MemberVenueMembership),
    venueStatuses: types.array(MemberVenueStatus),
    timeline: types.maybe(MemberTimeline),
    stampCardsList: types.maybeNull(types.array(StampCardItem)),
    stampCardsTotalStamps: types.maybeNull(types.number),
    stampCardsTotalRewards: types.maybeNull(types.number),
    eventsActivated: types.maybeNull(types.boolean),
    eventsTotalTickets: types.maybeNull(types.number),
    eventsTotalCloakrooms: types.maybeNull(types.number),
    eventsTotalSpent: types.maybeNull(types.number),
    eventsPurchaseDate: types.maybeNull(types.string),
  }).views(self => ({
    activeStatuses(venue: IVenue) {
      log.debug("Member: statuses")
      return self.venueStatuses.filter((memberVenueStatus: IMemberVenueStatus) => memberVenueStatus.id.restaurantId.id === venue.id)
    },
    incompleteStampCardsList() {
      return self.stampCardsList?.filter(card => card.status === "INCOMPLETE") || [];
    },
  })).actions(self => ({

    removeStatus(memberVenueStatus: IMemberVenueStatus) {
      log.debug("REMOVE STATUS")
      self.venueStatuses.remove(memberVenueStatus)
    },
    addStatus(venueStatus: IVenueStatus) {
      let status
      if ((venueStatus.type === VENUE_STATUS_TYPE_PURCHASE || venueStatus.type === VENUE_STATUS_TYPE_PRIVATE) && venueStatus.payments.type === VENUE_STATUS_PAYMENT_TYPE_FIXED) {
        log.debug(venueStatus.payments.validFrom, venueStatus.payments.validThrough)
        status = MemberVenueStatus.create({
          id: venueStatus.id,
          validFrom: moment.utc(venueStatus.payments.validFrom).toISOString(),
          validThrough: moment.utc(venueStatus.payments.validThrough).toISOString()
        })
      } else if ((venueStatus.type === VENUE_STATUS_TYPE_PURCHASE || venueStatus.type === VENUE_STATUS_TYPE_PRIVATE) && venueStatus.payments.type === VENUE_STATUS_PAYMENT_TYPE_FLEXIBLE) {
        status = MemberVenueStatus.create({
          id: venueStatus.id,
          validFrom: moment.utc().startOf("day").toISOString(),
          validThrough: moment.utc().endOf("day").add(venueStatus.payments.duration, 'days').toISOString()
        })
      } else {
        status = MemberVenueStatus.create({
          id: venueStatus.id,
          validFrom: moment.utc().startOf("day").toISOString(),
          validThrough: null
        })
      }

      // @ts-ignore
      self.venueStatuses.push(status)
      return status
    },
    updateExternalIdentifier(memberVenueStatusId: string, newExternalIdentifier: string | null) {
      log.debug("UPDATE EXTERNAL IDENTIFIER");
   
      const statusToUpdate = self.venueStatuses.find(status => status.userVenueStatusId === memberVenueStatusId);
      if (statusToUpdate) {
        statusToUpdate.externalIdentifier = newExternalIdentifier;
      } else {
        log.warn("MemberVenueStatus not found for updating external identifier");
      }
    },
  }))

export type IMember = Instance<typeof Member>
