import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { observer } from "mobx-react"
import { useState } from "react"
import Button from "react-bootstrap/Button"
import Dropdown from "react-bootstrap/Dropdown"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { MembersStatusSubscriptionsModifyLocation, MembersStatusesModifyLocation } from "../../Locations"
import { SummaryHeader, SummaryItem } from "../../components/SummaryHeader"
import UpgradeToPremiumModal from "../../components/UpgradeToPremiumModal"
import { useStores } from "../../hooks/UseStores"
import { IVenue } from "../../models/Venue"
import { STATUS_TYPE } from "../ManageMemberStatusesScreen"




const MembersScreenSummaryHeaders = observer(() => {
  const { userSessionStore, memberStore } = useStores()

  const { t } = useTranslation()
  const history = useHistory()
  const [isPremiumModalShown, setIsPremiumModalShown] = useState(false)

  const summaries: Array<SummaryItem> = [
    {
      title: t("memberScreen.summary.totalMembersTitle"),
      value: memberStore?.stats?.membersCount?.toString() || '0',
      footer: t("memberScreen.summary.totalMembersFooter"),
    },
    {
      title: t("memberScreen.summary.newMembersTitle"),
      value: memberStore?.stats?.membersLastMonth?.toString() || '0',
      footer: t("memberScreen.summary.newMembersFooter"),
    },
  ]

  const venueStatusesStats = memberStore!.stats?.venueStatuses || []

  venueStatusesStats.forEach((venueStatusStat: any) => {
    summaries.push({
      venue: userSessionStore!.currentOrganization!.isSingleVenue() ? undefined : venueStatusStat.id?.restaurantId?.name,
      title: venueStatusStat.id.localeTitle,
      value: venueStatusStat.membersCount,
      footer: t("memberScreen.summary.statusMembersFooter"),
    })
  })

  const isOrganizationPremium = userSessionStore?.currentOrganization?.isPremium


  const actionButtons = (
    <>
       <UpgradeToPremiumModal showModal={isPremiumModalShown} setShowModal={setIsPremiumModalShown} />
    {userSessionStore!.isOrganizationContext() ?
        <Dropdown
          /* @FIXME: https://github.com/react-bootstrap/react-bootstrap/issues/5409 */
          onClick={(e: any) => e.stopPropagation()}
          className="mr-2"
        >
          <Dropdown.Toggle variant="success" id="dropdown-edit-products-org">
            <FontAwesomeIcon className="mr-2" icon={["fal", "arrows-rotate"]}/>{t("memberScreen.sellSubscriptions")}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {userSessionStore!.currentOrganization!.venues!.map((venue: IVenue) => {
              return (
                <Dropdown.Item key={venue.id}
                               onClick={() => history.push(MembersStatusSubscriptionsModifyLocation.toUrl({
                                 ...venue.urlFields(),
                                 statusType: STATUS_TYPE.PUBLIC
                               }))}
                >{venue.name}</Dropdown.Item>
              )
            })}
          </Dropdown.Menu>
        </Dropdown>
        :
        <Button
          className="mr-2"
          onClick={() => history.push(MembersStatusSubscriptionsModifyLocation.toUrl({
            ...userSessionStore!.selectedBranch!.urlFields(),
            statusType: STATUS_TYPE.PUBLIC
          } as IVenue))}
          variant="success"><FontAwesomeIcon className="mr-2"
                                             icon={["fal", "arrows-rotate"]}/>{t("memberScreen.sellSubscriptions")}
        </Button>
      }
      {userSessionStore!.isOrganizationContext() ?
        <Dropdown
          /* @FIXME: https://github.com/react-bootstrap/react-bootstrap/issues/5409 */
          onClick={(e: any) => e.stopPropagation()}
          className="mr-2"
        >
          <Dropdown.Toggle variant="success" id="dropdown-edit-products-org">
            <FontAwesomeIcon className="mr-2" icon={["fal", "credit-card-blank"]}/>{t("memberScreen.sellCards")}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {userSessionStore!.currentOrganization!.venues!.map((venue: IVenue) => {
              return (
                <Dropdown.Item key={venue.id}
                               onClick={() => history.push(MembersStatusesModifyLocation.toUrl({
                                 ...venue.urlFields(),
                                 statusType: STATUS_TYPE.PUBLIC
                               }))}
                >{venue.name}</Dropdown.Item>
              )
            })}
          </Dropdown.Menu>
        </Dropdown>
        :
        <Button
          className="mr-2"
          onClick={() => history.push(MembersStatusesModifyLocation.toUrl({
            ...userSessionStore!.selectedBranch!.urlFields(),
            statusType: STATUS_TYPE.PUBLIC
          } as IVenue))}
          variant="success"><FontAwesomeIcon className="mr-2"
                                             icon={["fal", "credit-card-blank"]}/>{t("memberScreen.sellCards")}
        </Button>
      }

      {userSessionStore!.isOrganizationContext() ?
        <Dropdown
          /* @FIXME: https://github.com/react-bootstrap/react-bootstrap/issues/5409 */
          // onClick={(e: any) => e.stopPropagation()}
          onClick={(e: any) => isOrganizationPremium ? e.stopPropagation() : setIsPremiumModalShown(true)}
        >
          <Dropdown.Toggle variant="success" id="dropdown-edit-products-org">
            <FontAwesomeIcon  icon={["fal", "star"]} style={{marginRight: 5}}/>{t("memberScreen.giveCards")}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {userSessionStore!.currentOrganization!.venues!.map((venue: IVenue) => {
              return (
                <Dropdown.Item key={venue.id}
                  onClick={() => history.push(MembersStatusesModifyLocation.toUrl({
                    ...venue.urlFields(),
                    statusType: STATUS_TYPE.PRIVATE
                  }))}
                >{venue.name}</Dropdown.Item>
              )
            })}
          </Dropdown.Menu>
        </Dropdown>
        :
        <Button
          onClick={() => isOrganizationPremium ? history.push(MembersStatusesModifyLocation.toUrl({
                          ...userSessionStore!.selectedBranch!.urlFields(),
                          statusType: STATUS_TYPE.PRIVATE
                        } as IVenue))
                        :
                        setIsPremiumModalShown(true)
                  }
          variant="success"><FontAwesomeIcon className="mr-2"
                                             icon={["fal", "star"]}/>{t("memberScreen.giveCards")}
        </Button>
      }
    </>
  )



  return   <SummaryHeader summaries={summaries} isSingleVenue={userSessionStore!.currentOrganization!.isSingleVenue()} buttons={actionButtons}/>
})


export default MembersScreenSummaryHeaders
